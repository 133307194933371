$text-color: #555555;
$primary-color: #3d6ca9;

body {
  margin: 0;
  font-family: "Roboto", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.doc-portal-content {
  color: $text-color;
  font-size: 1rem;
  line-height: 1.5em;
  h1 {
    margin-bottom: 2rem;
    color: #000;
    font-weight: 600;
    font-size: 1.675rem;
  }
  h2 {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    color: #000;
    font-weight: 600;
    font-size: 1.3rem;
    padding-top: 1.5rem;

    border-top: 1px solid #e5e5e5;
  }
  h3 {
    margin-top: 1rem;
    line-height: 1.25;
    color: #000;
    font-weight: 500;
    font-size: 1.25rem;
  }

  a,
  a:visited {
    color: $primary-color;
    font-weight: 500;
  }
}

.doc-portal {
  display: flex;

  &-main {
    flex: 1;
  }
  &-container {
    padding: 1rem 1rem 1rem 3rem;
  }
  &-sidebar {
    height: 100vh;
    position: sticky;
    overflow-y: auto;
    overflow-x: hidden;
    top: 0;
    width: 18rem;
    background-color: #f8f8f8;
    border-right: 1px solid #e5e5e5;
    line-height: 1.5em;
    user-select: none;
    .doc-portal-logo {
      padding: 1rem;
    }
    .doc-portal-search {
      display: flex;
      padding: 0 1rem;
      position: relative;
      input {
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        box-shadow: 0 1px 8px 0 rgba(116, 129, 141, 0.1);
        width: 100%;
        padding: 7px;
        height: 20px;
        border-radius: 5px;
        outline: none;
        font-size: 0.9rem;
      }
      &-icon {
        position: absolute;
        top: 0;
        right: 15px;
        padding: 10px;
        color: $text-color;
        cursor: pointer;
      }
    }
    .doc-portal-links {
      margin-top: 1rem;

      .doc-portal-link {
        position: relative;
        font-weight: 600;
        color: #5c6975;
        &-header {
          display: block;
          padding: 0.5em 1em 0.5em 1em;
          min-width: 0;
          word-wrap: break-word;
          color: #5c6975;
          text-decoration: none;

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }
        }
        &-parent {
          cursor: pointer;
        }
        &-child {
          font-weight: 400;
          font-size: 0.9em;
          margin-left: 1.5rem;
        }
        &-toggle {
          top: 13px;
          right: 25px;
          position: absolute;
        }
        &-method {
          width: 3rem;
          text-transform: uppercase;

          margin-right: 1rem;
          font-weight: 600;
          &-get {
            color: #61affe;
          }
          &-post {
            color: #49cc90;
          }
          &-put {
            color: #fca130;
          }
          &-patch {
            color: #50e3c2;
          }
          &-delete {
            color: #f93e3e;
          }
        }
      }
    }
  }
  .doc-portal-summary {
    padding: 0.5rem 0 0 0;

    a {
      text-decoration: none;
    }
    .doc-portal-resource {
      // border: 1px solid #a8a8a8;
      border-radius: 3px;
      margin: 0.5rem 0rem 3rem 0rem;
      // padding: 0.5rem 0.5rem 0.5rem 0.5rem;

      h3 {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        border-bottom: solid 1px #d9d9d9;
      }

      .doc-portal-resource-subresources {
        display: flex;
        flex-wrap: wrap;

        .doc-portal-subresource {
          display: block;
          position: relative;
          width: 26%;
          padding: 1rem 4rem 1rem 1rem;
          margin: 0.5rem;
          // margin-right: 1rem;
          // margin-left: 0;
          // margin-bottom: 1.5rem;
          border: 1px solid #e5e5e5;
          border-radius: 3px;
          box-shadow: 0 3px 8px 0 rgba(116, 129, 141, 0.1);
          &:hover {
            border-color: $primary-color;
          }
          &-name {
            font-size: 1.2em;
            font-weight: 600;
          }
          &-description {
            color: $text-color;
          }
          &-arrow {
            position: absolute;
            top: calc(50% - 10px);
            right: 20px;
          }
        }
      }
    }
  }
  &-resource-nav {
    display: flex;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 999;
    align-items: center;
    align-content: flex-start;
    padding: 1rem 1rem 1rem 3rem;
    border-bottom: 1px solid #e5e5e5;
    &-previous {
      color: #000;
      padding: 0.5rem;
      border: 1px solid #e5e5e5;
      border-radius: 1.2rem;
      box-shadow: 0 3px 3px 0 rgba(116, 129, 141, 0.1);
      margin-right: 2rem;
    }

    .doc-portal-version-selector {
      margin: 0 1rem;
      width: 150px;
    }
    &-download {
      margin-left: 1rem;
      cursor: pointer;
      padding: 0.5rem 0.6rem;
      border: 1px solid #e5e5e5;
      border-radius: 1.2rem;
      box-shadow: 0 3px 3px 0 rgba(116, 129, 141, 0.1);
    }
  }
}

.swagger-ui {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
}
